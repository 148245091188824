import styled, { css } from 'styled-components'
import { transparentize } from 'polished'

export const PageTitle = styled.h1`
  display: block;
  color: ${transparentize(0.4, 'purple')};
  border-bottom: 3px solid ${transparentize(0.8, 'purple')};
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  font-weight: normal;
`

export const Meta = styled.div`
  color: #666;
  font-style: italic;
  margin-bottom: 2rem;

  ${PageTitle} + & {
    margin-top: -1rem;
  }
`

interface HeadingProps {
  bordered?: boolean
}

export const Heading2 = styled.h2<HeadingProps>`
  display: block;
  color: ${transparentize(0.3, 'purple')};
  margin-bottom: 1rem;
  font-weight: normal;
  ${p =>
    p.bordered &&
    css`
      border-bottom: 1px solid ${transparentize(0.8, 'purple')};
      padding-bottom: 0.5rem;
    `}
  p + & {
    margin-top: 2rem;
  }
`
