import Header from '../Header'
import { Container } from '../styled'
import { PageTitle } from '../styled/titles'
import styled from 'styled-components'
import media from 'styled-media-query'
import { PageFooter } from '../PageFooter'

const Main = styled.main`
  flex: 1 0 auto;
  padding: 0 1rem;
  ${media.greaterThan('huge')`padding: 0;`}
`

interface PageLayoutProps {
  heading?: string
  narrow?: boolean
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  heading,
  narrow,
}) => {
  return (
    <>
      <Header />
      <Main>
        <Container
          vAlign={'start'}
          style={{ marginTop: '4rem' }}
          narrow={narrow}
        >
          {heading && <PageTitle>{heading}</PageTitle>}
          {children}
        </Container>
      </Main>

      <PageFooter />

      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

        html,
        body {
          height: 100%;
          padding: 0;
          margin: 0;
        }

        body,
        #__next {
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        html,
        body,
        button,
        input,
        select,
        textarea {
          font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, Segoe UI,
            Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
            Helvetica Neue, sans-serif;
        }

        p,
        li {
          font-weight: 400;
        }

        li p:last-child {
          margin-bottom: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-top: 0;
        }

        p {
          margin: 0 0 1rem;
          line-height: 1.8rem;
        }

        * {
          box-sizing: border-box;
        }
      `}</style>
    </>
  )
}
